import { CookiesStorage } from '@Core/Communication/GlobalStore/CookiesStorage/CookiesStorage'
import axios, { Axios } from 'axios'
import { BaseApi } from 'src/Data/DataSource/API/Entity/BaseAPIEntity'
const API_URL =
    import.meta.env.MODE === 'test'
        ? import.meta.env.VITE_BASE_MOCK_API
        : import.meta.env.VITE_BASE_URL_API || 'https://api-bshub.buzato.dev.br'

const storage = new CookiesStorage()
export class Api implements BaseApi {
    private axios: Axios
    public baseURL: string = ''

    constructor(baseURL: string = API_URL as string) {
        this.axios = axios.create({
            baseURL,
        })
        this.getInterceptors()
        this.baseURL = baseURL
    }

    getSource() {
        return this.baseURL
    }

    getInstance() {
        return this.axios
    }

    getInterceptors() {
        this.axios.interceptors.request.use(
            function (config: any) {
                config.headers.Authorization = `Bearer ${storage.getItem(
                    '@ofc:accessToken'
                )}`
                // Do something before request is sent
                return config
            },
            function (error: any) {
                // Do something with request error
                return Promise.reject(error)
            }
        )

        this.axios.interceptors.response.use(
            function (response: any) {
                return response
            },
            function (error: any) {
                const { status } = error.response
                if (
                    !import.meta.env.VITE_MOCK_AUTH &&
                    (status === 401 || status === 403)
                ) {
                    storage.removeItem('@ofc:accessToken')
                }
                return Promise.reject(error)
            }
        )
    }

    get(url: string, config?: any) {
        return this.axios.get(url, config)
    }

    post(url: string, data?: any, config?: any) {
        return this.axios.post(url, data, config)
    }

    put(url: string, data?: any, config?: any) {
        return this.axios.put(url, data, config)
    }

    delete(url: string, config?: any) {
        return this.axios.delete(url, config)
    }

    patch(url: string, data?: any, config?: any) {
        return this.axios.patch(url, data, config)
    }
}

export const API = new Api()
export type ApiType = typeof API
