import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { useYupValidationResolver } from '@Core/Hooks/useYupValidationResolver'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { loginTestID } from './Login.constants'
import { EmailPasswordContainer } from './Login.styles'
import { Button } from '@Core/Components'

const validationSchema = yup.object({
    username: yup.string().required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatória'),
})
export const LoginForm = ({ uiAuthenticate, loading }) => {
    const resolver = useYupValidationResolver(validationSchema)
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            username: undefined,
            password: undefined,
        },
        resolver,
    })
    const handleLogin: SubmitHandler<Record<string, any>> = ({
        username,
        password,
    }) => {
        uiAuthenticate(username, password)
    }

    const handleError = (error) => {
        Object.entries(error).forEach(([key, value]) => {
            setError(key, {
                type: 'manual',
                message: value.message,
            })
        })
    }

    if (loading) {
        return <Loader />
    }

    return (
        <EmailPasswordContainer>
            <form
                onSubmit={handleSubmit(handleLogin, handleError)}
                className="grid w-full gap-3"
            >
                <Input
                    control={control}
                    name="username"
                    useUnform={false}
                    placeholder="E-mail"
                    error={!!errors.username?.message}
                    helperText={errors.username?.message}
                />
                <Input
                    control={control}
                    name="password"
                    useUnform={false}
                    type="password"
                    placeholder="Senha"
                    error={!!errors.password?.message}
                    helperText={errors.password?.message}
                />
                <Button  key={loginTestID.button} type="submit">
                    Login
                </Button>
                {/*<Button data-testid={loginTestID.button + `-ui`} type="submit">*/}
                {/*    Login*/}
                {/*</Button>*/}
            </form>
        </EmailPasswordContainer>
    )
}
