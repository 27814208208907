import { db, storage } from '@/utils/firebase'
import { RootState } from '@Core/Communication'
import { Loader } from '@Core/Components/Loader/Loader'
import { doc, getDoc } from 'firebase/firestore'
import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { HomeBackground, HomeDescription, HomeTitle } from './Home.styles'

export const Home = () => {
    const { user } = useSelector((state: RootState) => state.user)

    const [wallpaper, setWallpaper] = useState('')
    const [description, setDescription] = useState('')
    const [salutation, setSalutation] = useState('')
    const [color, setColor] = useState('')
    const [loadingImage, setLoadingImage] = useState(true)
    const [loadingText, setLoadingText] = useState(true)

    const getImage = () => {
        setLoadingImage(true)
        const storageRef = ref(storage, 'home-wallpaper.jpg')
        getDownloadURL(storageRef).then((url) => {
            setWallpaper(url)
            setLoadingImage(false)
        })
    }

    const getText = async () => {
        setLoadingText(true)
        const saluRef = doc(db, 'settings', 'home')
        const saluSnap = await getDoc(saluRef)
        if (saluSnap.exists()) {
            setSalutation(saluSnap.data().salutation)
            setDescription(saluSnap.data().description)
            setColor(saluSnap.data().color)
        }
        setLoadingText(false)
    }

    useEffect(() => {
        getImage()
        getText()
    }, [])

    if (!user?.givenName || loadingImage || loadingText) return <Loader />

    return (
        <HomeBackground url={wallpaper}>
            <HomeTitle className="text-4xl" color={color}>
                {salutation}, {user?.givenName}!
            </HomeTitle>
            <HomeDescription className="text-xl" color={color}>
                {description}
            </HomeDescription>
        </HomeBackground>
    )
}
