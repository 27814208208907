import { roles } from '@Balance/roles'
import {
    exportClientStockDictionary,
    exportClientStockValueDictionary,
    exportStockData,
} from '@Clients/ClientDetails/ClientDetails.utils'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { IconButton } from '@Core/Components/IconButton/IconButton'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { MovementsTable } from '@Core/Components/MovementsTable/MovementsTable'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { StockTable } from '@Core/Components/StockTable/StockTable'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { StockDocument } from '@Reports/Pdf/Pdf'
import { API } from '@Services/api'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import DeleteIcon from '@assets/delete.png'
import { usePDF } from '@react-pdf/renderer'
import { Form } from '@unform/web'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSupplierManager } from '../Hooks/useSupplierManager'

export const SupplierDetails = () => {
    const [editMode, setEditMode] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const { getSupplierByID, state, handleEditSupplier } =
        useSupplierManager(API)
    
    const stockDocument = StockDocument({
        title: `Saldo - ${state?.corporateName}`,
        // @ts-ignore
        stocks: state?.stocks,
    })
    const [instance, update] = usePDF({
        document: stockDocument,
    })
    const {
        handleChange,
        handleSubmit,
        handleNewAdditionalEmail,
        handleRemoveAdditionalMail,
    } = handleEditSupplier(String(id))

    const toggleEditMode = () => setEditMode(!editMode)

    const exportStockMenuList = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(
                    exportStockData(state?.stocks, { supplier: state }),
                    `${state?.corporateName} - Saldo`,
                    {
                        columnsDict: exportClientStockDictionary,
                        valuesDict: exportClientStockValueDictionary,
                        sheetName: 'Saldo',
                    }
                )
            },
        },
        {
            label: 'Exportar para PDF',
            href: instance.url,
            attr: {
                download: `${state?.corporateName} - Saldo.pdf`,
                target: '_blank',
            },
        },
    ]

    const handleSave = () => {
        handleSubmit()
        setEditMode(false)
    }

    const handleCancel = () => {
        setEditMode(false)
        getSupplierByID(String(id))
    }

    useEffect(() => {
        getSupplierByID(String(id))
    }, [id])

    useEffect(() => {
        update(stockDocument)
    }, [state])

    if (!state) {
        return <Loader />
    }

    return (
        <Container>
            <Form onSubmit={() => {}}>
                <BackButton />
                <ContainerHeader>
                    <h1 className="text-2xl font-bold">
                        {state.corporateName}
                    </h1>
                    {!editMode && (
                        <RightContent>
                            <PermissionWrapper
                                permission={
                                    roles.suppliers.permissions.update.key
                                }
                            >
                                <Button
                                    variant={ButtonVariant.primary}
                                    onClick={toggleEditMode}
                                >
                                    Editar
                                </Button>
                            </PermissionWrapper>
                            <PermissionWrapper
                                permission={
                                    roles.movements.permissions.create.key
                                }
                            >
                                <Button
                                    variant={ButtonVariant.primary}
                                    onClick={() => {
                                        navigate(
                                            `/balance/supplier/new-movement/${id}`,
                                            {
                                                state: {
                                                    supplier: state,
                                                },
                                            }
                                        )
                                    }}
                                >
                                    Nova Movimentação de Entrada
                                </Button>
                            </PermissionWrapper>
                        </RightContent>
                    )}
                    {editMode && (
                        <RightContent>
                            <PermissionWrapper
                                permission={
                                    roles.suppliers.permissions.update.key
                                }
                            >
                                <Button
                                    variant={ButtonVariant.secondary}
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant={ButtonVariant.primary}
                                    onClick={handleSave}
                                >
                                    Salvar
                                </Button>
                            </PermissionWrapper>
                        </RightContent>
                    )}
                </ContainerHeader>
                <div className="mt-2 mb-2">
                    <Grid container lg={12}>
                        {editMode && (
                            <Grid item lg={6}>
                                <Input
                                    name="corporateName"
                                    label="Nome"
                                    value={state.corporateName}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}
                        <Grid item lg={6}>
                            <Input
                                disabled={!editMode}
                                name="mail"
                                label="E-mail"
                                type="mail"
                                value={state?.mail}
                                onChange={handleChange}
                            />
                        </Grid>
                        {state?.additionalEmails?.map((email, index) => (
                            <Grid
                                container
                                item
                                lg={6}
                                key={`additionalEmails[${index}]`}
                            >
                                <Grid item lg={10}>
                                    <Input
                                        disabled={!editMode}
                                        name={`additionalEmails[${index}]`}
                                        type="mail"
                                        label="E-mail"
                                        value={email}
                                        supportMessages={[
                                            {
                                                message: 'Adicional',
                                                variant: 'info',
                                            },
                                        ]}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {editMode && (
                                    <IconButton
                                        size="medium"
                                        onClick={() =>
                                            handleRemoveAdditionalMail({
                                                index,
                                            })
                                        }
                                    >
                                        <img
                                            src={DeleteIcon}
                                            alt="Remover e-mail"
                                        />
                                    </IconButton>
                                )}
                            </Grid>
                        ))}
                        {editMode && (
                            <Grid item lg={3}>
                                <Button
                                    variant={ButtonVariant.primary}
                                    onClick={handleNewAdditionalEmail}
                                >
                                    Adicionar E-mail
                                </Button>
                            </Grid>
                        )}
                        <Grid item lg={12}>
                            <TextArea
                                disabled={!editMode}
                                name="observation"
                                label="Observações"
                                minRows={3}
                                value={state?.observation}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Grid item lg={12}>
                    <StockTable
                        stocks={state.stocks || []}
                        exportOptions={exportStockMenuList}
                    />
                </Grid>
                <div className="mt-2">
                    <PermissionWrapper
                        permission={roles.movements.permissions.read.key}
                    >
                        <Grid container xs={12} lg={12}>
                            <Grid item lg={12}>
                                <h3 className="font-bold">Movimentações</h3>
                            </Grid>
                            <Grid item lg={12}>
                                <MovementsTable
                                    movements={state.movements || []}
                                    allMovements={state.movements || []}
                                    options={{
                                        count: state?.movements?.length || 0,
                                    }}
                                    hideColumns={['supplier']}
                                />
                            </Grid>
                        </Grid>
                    </PermissionWrapper>
                </div>
            </Form>
        </Container>
    )
}
