import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { useCommercialProducts } from '@Commercial/Products/Hooks/useProducts/useCommercialProducts'
import { roles } from '@Commercial/roles'
import { Button } from '@Core/Components'
import { AutoForm } from '@Core/Components/AutoForm/AutoForm'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { formatCurrency } from '@Utils/Formatters/currency'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { IconButton, InputAdornment, Switch } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { BsFillTrashFill, BsInfoCircle } from 'react-icons/bs'
import { IoDuplicate } from 'react-icons/io5'
import { MdDragHandle, MdOpenInNew } from 'react-icons/md'
import { CordType } from '../CordType/CordType'
import { GiftOptions } from '../GiftOptions/GiftOptions'
import { InventoryProductStock } from './ProductProposalForm.styles'
import { injectActive } from './ProductProposalForm.utils'

export const ProductProposalForm = ({
    disabled,
    isUnique,
    register,
    index,
    controller,
    setValue,
    state,
    onDelete,
    error,
    duplicateProposal,
    hasDragAndDrop = false,
    drag,
    preview,
}) => {
    const { products, getProducts } = useCommercialProducts()
    const { hasPermission } = usePermissions()
    const [isCordOpen, setIsCordOpen] = useState(false)
    const [isGiftOpen, setIsGiftOpen] = useState(false)
    const [isAlertOpen, setIsAlertOpen] = useState(false)

    const isOdd = index % 2 === 0

    const readCost = hasPermission(
        roles.proposal.permissions.read.permissions.readCost.key
    )

    const noMarkupLimit = hasPermission(
        roles.proposal.permissions.update.permissions.noMarkupLimit.key
    )

    const handleAutoCompleteChange =
        (name: string) =>
        (_: any, { value }: any) => {
            setValue(name, value)
        }

    const isJoker = state?.products[index].name === 'Outros'
    const isCord = state?.products[index]?.productType === 'dynamic'
    const isRoller = state?.products[index]?.productType === 'rollerClip'
    const isGift = state?.products[index]?.productType === 'gift'

    const markupLimits =
        state?.products[index].markup <
            Number(state?.products[index]?.minMarkup) ||
        state?.products[index].markup >
            Number(state?.products[index]?.maxMarkup)
    const markupError = !noMarkupLimit && markupLimits

    const active = state?.products[index]?.isActive

    const hasInventoryProduct = (state?.products[index]?.inventoryProduct || [])
        .length
    const hasInventoryProductStock =
        state?.products[index]?.inventoryProduct?.[0]?.stock
    const hasInventoryProductHasStock =
        hasInventoryProductStock > state?.products[index]?.quantity

    const productGridSize = [
        {
            isValid: isCord || isGift,
            grid: 3,
            gap: 0,
        },
        {
            isValid: isJoker,
            grid: 3,
            gap: 0,
        },
        {
            isValid: isRoller,
            grid: 3,
            gap: 3,
        },
        {
            isValid: hasInventoryProduct,
            grid: 3,
            gap: 6,
        },
        {
            isValid: true,
            grid: 3,
            gap: 9,
        },
    ].find(({ isValid }) => isValid)

    const InputAdornmentDict = {
        dynamic: (
            <InputAdornment position="end">
                <Button
                    type="button"
                    size="small"
                    disabled={disabled}
                    onClick={() => setIsCordOpen(true)}
                >
                    <MdOpenInNew />
                </Button>
            </InputAdornment>
        ),
        gift: (
            <InputAdornment position="end">
                <Button
                    type="button"
                    size="small"
                    disabled={disabled}
                    onClick={() => setIsGiftOpen(true)}
                >
                    <MdOpenInNew />
                </Button>
            </InputAdornment>
        ),
        warning: (
            <InputAdornment position="end">
                <BsInfoCircle />
            </InputAdornment>
        ),
        money: (
            <InputAdornment position="start" className="p-0">
                <span className="text-xs p-0">R$</span>
            </InputAdornment>
        ),
    }

    const inputs = useMemo(
        () => [
            {
                grid: productGridSize?.grid,
                name: active ? `products[${index}]` : `products[${index}].name`,
                label: 'Produto',
                type: active ? 'autocomplete' : 'text',
                key: active ? `products[${index}]` : `products[${index}].name`,
                className:
                    'minput-p-0 w-full max-w-full font-bold-minput isProduct',
                size: 'small',
                control: controller,
                labelKey: 'name',
                disabled: !active,
                freeSolo: !active,
                autoComplete: 'off',
                value: state?.products[index],
                register: register(`products[${index}]`),
                onChange: handleAutoCompleteChange(`products[${index}]`),
                inputProps: {
                    InputProps:
                        active && (isCord || isGift)
                            ? {
                                  endAdornment:
                                      InputAdornmentDict[
                                          state?.products[index]
                                              ?.productType as keyof typeof InputAdornmentDict
                                      ],
                              }
                            : {
                                  freeSolo: !active,
                                  disabled: true,
                                  autoComplete: 'off',
                              },
                },
                results: active
                    ? formattedAutocomplete<DBCommercialProduct>(
                          injectActive(products),
                          'name'
                      )
                    : [],
            },
            {
                grid: productGridSize?.gap,
                hide: productGridSize?.gap === 0,
                type: 'custom',
                Component: () => <div className="col-span-1 w-full"></div>,
            },
            {
                grid: 3,
                hide: !hasInventoryProduct,
                type: 'custom',
                Component: () => (
                    <InventoryProductStock
                        className="h-[25px] rounded text-center"
                        out={!hasInventoryProductHasStock}
                        key={`products[${index}].inventoryProduct`}
                    >
                        <span>
                            Estoque atual: {hasInventoryProductStock || 0}
                        </span>
                    </InventoryProductStock>
                ),
            },
            {
                grid: 3,
                name: `products[${index}].quantityPerPage`,
                label: 'Unidades por pagina',
                size: 'small',
                className: 'minput-p-0',
                control: controller,
                hide: !isRoller,
                disabled: true,
                type: 'text',
                rows: 5,
                key: `products[${index}].quantityPerPage`,
                value: state?.products[index].quantityPerPage || '',
            },
            {
                grid: 3,
                name: `products[${index}].pages`,
                size: 'small',
                className: 'minput-p-0',
                label: `Pagina${
                    state?.products[index]?.pages > 1 && 's'
                } necessária`,
                control: controller,
                hide: !isRoller,
                disabled: true,
                type: 'text',
                rows: 5,
                key: `products[${index}].pages`,
                value: state?.products[index].pages || '',
            },
            {
                grid: 3,
                name: `products[${index}].description`,
                size: 'small',
                className: 'minput-p-0',
                label: 'Descrição',
                control: controller,
                hide: !isJoker && !isGift,
                disabled: !active,
                type: 'text',
                inputProps:{  maxLength: 42 },
                key: `products[${index}].description`,
                value: String(state?.products[index]?.description)?.replace(/\n/g, '  ') || '',
            },
            {
                grid: 6,
                hide: !isJoker,
                type: 'custom',
                Component: () => <div className="col-span-1 w-full"></div>,
            },
            {
                grid: 3,
                hide: !isCord,
                name: `cordType`,
                size: 'small',
                label: 'Tipo',
                className: 'w-full max-w-full minput-p-0',
                withHover: true,
                type: 'input',
                key: `cordType`,
                control: controller,
                labelKey: 'name',
                disabled: true,
                value: state?.products[index]?.cordType?.name,
                register: register(`cordType`),
                onChange: handleAutoCompleteChange(
                    `products[${index}].cordType.name`
                ),
                InputProps:
                    active && (isCord || isGift)
                        ? {
                              endAdornment:
                                  InputAdornmentDict[
                                      state?.products[index]
                                          ?.productType as keyof typeof InputAdornmentDict
                                  ],
                          }
                        : {},
            },
            {
                grid: 3,
                hide: !isCord,
                name: `size`,
                label: 'Especificação',
                size: 'small',
                className: 'w-full max-w-full minput-p-0',
                withHover: true,
                type: 'input',
                key: `size`,
                control: controller,
                labelKey: 'name',
                disabled: true,
                value: state?.products[index]?.size?.value,
                register: register(`size`),
                InputProps:
                    active && (isCord || isGift)
                        ? {
                              endAdornment:
                                  InputAdornmentDict[
                                      state?.products[index]
                                          ?.productType as keyof typeof InputAdornmentDict
                                  ],
                          }
                        : {},
            },
            {
                grid: 3,
                hide: !isGift,
                name: `priceRange`,
                label: 'Especificação',
                size: 'small',
                className: 'w-full max-w-full minput-p-0',
                withHover: true,
                type: 'input',
                key: `priceRange`,
                control: controller,
                labelKey: 'name',
                disabled: true,
                value: state?.products[index]?.priceRange?.name,
                register: register(`priceRange`),
                InputProps:
                    active && (isCord || isGift)
                        ? {
                              endAdornment:
                                  InputAdornmentDict[
                                      state?.products[index]
                                          ?.productType as keyof typeof InputAdornmentDict
                                  ],
                          }
                        : {},
            },
            {
                grid: 3,
                hide: !isGift && !isCord,
                label: 'Acessórios',
                className: 'w-full max-w-full minput-p-0',
                size: 'small',
                withHover: true,
                type: 'input',
                control: controller,
                disabled: true,
                value:
                    [
                        ...(state?.products?.[index]?.giftAccessories || []),
                        ...(state?.products?.[index]?.cordAccessories || []),
                    ]
                        ?.map((item) => item?.label)
                        ?.join(', ') || 'Nenhum acessório',
                InputProps:
                    active && (isCord || isGift)
                        ? {
                              endAdornment:
                                  InputAdornmentDict[
                                      state?.products[index]
                                          ?.productType as keyof typeof InputAdornmentDict
                                  ],
                          }
                        : {},
            },
            {
                grid: 1,
                name: `products[${index}].quantity`,
                size: 'small',
                className: 'minput-p-0',
                label: 'Quantidade',
                control: controller,
                type: 'input',
                disabled: !active || disabled,
                hide: !state?.products[index].unitCost && !isJoker && !isGift,
                value: state?.products[index].quantity,
                error: Boolean(
                    isGift &&
                        state?.products[index].quantity <
                            state?.products[index].minQuantity
                ),
                withHover: Boolean(
                    isGift &&
                        state?.products[index].quantity <
                            state?.products[index].minQuantity
                ),
                helper: `A quantidade deve ser maior ou igual a ${Number(
                    state?.products[index]?.minQuantity
                )}`,
                InputProps: Boolean(
                    isGift &&
                        state?.products[index].quantity <
                            state?.products[index].minQuantity
                )
                    ? {
                          endAdornment: InputAdornmentDict.warning,
                      }
                    : {},
                inputProps: {
                    min: isGift ? state?.products[index].minQuantity : 0,
                },
                key: `products[${index}].quantity`,
                register: register(`products[${index}].quantity`, {
                    valueAsNumber: true,
                    pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                    },
                }),
            },
            {
                grid: 1,
                name: `products[${index}].freight`,
                label: 'Frete',
                size: 'small',
                className: 'minput-p-0',
                control: controller,
                type: 'input',
                disabled: !active || disabled,
                required: true,
                error: Boolean(!state?.products[index].freight),
                hide: !state?.products[index].unitCost && !isJoker,
                key: `products[${index}].freight`,
                value: state?.products[index].freight,
                InputProps: {
                    startAdornment: InputAdornmentDict.money,
                },
            },
            {
                grid: 2,
                name: `products[${index}].additionalProductUnitCost`,
                label: 'Custo unitário',
                size: 'small',
                className: 'minput-p-0',
                helper: 'Produto a ser personalizado',
                control: controller,
                hide: !isGift,
                key: `products[${index}].additionalProductUnitCost`,
                type: 'input',
                disabled: !isGift || !active || disabled,
                InputProps: isGift && {
                    startAdornment: InputAdornmentDict.money,
                },
                register: register(
                    `products[${index}].additionalProductUnitCost`
                ),
                value: state?.products[index].additionalProductUnitCost,
            },
            {
                grid: 1,
                size: 'small',
                className: 'minput-p-0',
                name: `products[${index}].multiplier`,
                label: 'Multiplicar',
                control: controller,
                hide: !isGift,
                key: `products[${index}].multiplier`,
                type: 'input',
                disabled: !isGift || !active || disabled,
                register: register(`products[${index}].multiplier`, {
                    valueAsNumber: true,
                }),
                value: state?.products[index].multiplier,
            },
            {
                grid: 2,
                size: 'small',
                className: 'minput-p-0',
                name: isJoker
                    ? `products[${index}].unitCost`
                    : `products[${index}].unitCostTotal`,
                label: 'Custo unitário',
                control: controller,
                hide: !isJoker,
                key: `products[${index}].unitCost`,
                type: 'input',
                disabled: !isJoker || !active || disabled,
                InputProps: isJoker && {
                    startAdornment: InputAdornmentDict.money,
                },
                register: isJoker
                    ? register(`products[${index}].unitCost`, {
                          valueAsNumber: true,
                      })
                    : undefined,
                value: isJoker
                    ? state?.products[index].unitCost || 0
                    : formatCurrency(
                          state?.products[index].unitCostTotal,
                          false
                      ),
            },
            {
                grid: 1,
                type: 'custom',
                Component: () => <div className="col-span-1 w-full"></div>,
                hide: !isJoker,
            },
            {
                grid: 2,
                size: 'small',
                className: 'minput-p-0',
                name: isJoker
                    ? `products[${index}].unitCostTotalFull`
                    : `products[${index}].unitCostTotal`,
                label:
                    !isJoker && !isGift
                        ? 'Custo unitário'
                        : 'Custo unitário total',
                control: controller,
                hide:
                    (!readCost || !state?.products[index].unitCostTotal) &&
                    !isJoker,
                key: `products[${index}].unitCostTotal`,
                disabled: true,
                value: formatCurrency(
                    isJoker
                        ? state?.products[index].unitCostTotalFull
                        : state?.products[index].unitCostTotal,
                    false
                ),
            },
            {
                grid: 1,
                type: 'custom',
                Component: () => <div className="col-span-1 w-full"></div>,
                hide: isGift || isJoker,
            },
            {
                grid: 2,
                type: 'custom',
                Component: () => <div className="col-span-2 w-full"></div>,
                hide: isJoker || isGift,
            },
            {
                grid: 1,
                disabled: !active || disabled,
                size: 'small',
                className: `minput-p-0 ${
                    markupLimits && noMarkupLimit && 'warning'
                }`,
                name: `products[${index}].markup`,
                withHover: Boolean(markupLimits && noMarkupLimit),
                helper: (
                    <Alert severity="warning">
                        <AlertTitle>Atenção!</AlertTitle>
                        Você está fora dos limites indicados de markup para este
                        produto {state.products[index]?.minMarkup}% até{' '}
                        {state.products[index]?.maxMarkup}%
                        <br />
                        Você tem permissão para ignorar este limite.
                    </Alert>
                ),
                label: 'Markup',
                error: markupError,
                errors: {
                    [`products[${index}].markup`]: {
                        message: markupError
                            ? `O Valor deve ser maior que ${Number(
                                  state?.products[index]?.minMarkup
                              )} e menor que ${Number(
                                  state?.products[index]?.maxMarkup
                              )}`
                            : '',
                    },
                },
                InputProps:
                    markupLimits && noMarkupLimit
                        ? {
                              endAdornment: InputAdornmentDict.warning,
                          }
                        : {},
                control: controller,
                hide: !state?.products[index].unitCost && !isJoker,
                type: 'number',
                inputProps: {
                    min:
                        (!noMarkupLimit &&
                            Number(state?.products[index]?.minMarkup)) ||
                        0,
                    max:
                        (!noMarkupLimit &&
                            Number(state?.products[index]?.maxMarkup)) ||
                        9999,
                },
                key: `products[${index}].markup`,
                value: state?.products[index].markup || 0,
            },
            {
                grid: 2,
                size: 'small',
                name: `products[${index}].unitPriceTotal`,
                label: 'Venda unitária',
                disabled: true,
                control: controller,
                hide: !state?.products[index].unitCost && !isJoker,
                key: `products[${index}].unitPriceTotal`,
                className: active
                    ? 'highlight minput-p-0 font-bold-minput'
                    : 'minput-p-0',
                value: formatCurrency(
                    state?.products[index].unitPriceTotal || 0
                ),
            },
            {
                grid: 2,
                size: 'small',
                className: 'minput-p-0',
                name: `products[${index}].totalPriceFull`,
                label: 'Venda total',
                control: controller,
                hide: !state?.products[index].unitCost && !isJoker,
                disabled: true,
                key: `products[${index}].totalPriceFull`,
                value: formatCurrency(
                    state?.products[index].totalPriceFull || 0
                ),
            },
        ],
        [state, products, productGridSize]
    )

    const handleCloseCord = () => {
        setIsCordOpen(false)
    }
    const handleCloseGift = () => {
        setIsGiftOpen(false)
    }

    const toggleAlert = () => {
        setIsAlertOpen(!isAlertOpen)
    }

    useEffect(() => {
        getProducts()
    }, [])

    useEffect(() => {
        setIsCordOpen(
            isCord &&
                !state?.products[index]?.cordAccessories?.length &&
                !Boolean(state?.products[index]?.size?.value) &&
                !Boolean(state?.products[index]?.cordType?.name)
        )
    }, [isCord])
    useEffect(() => {
        setIsGiftOpen(
            isGift && !Boolean(state?.products[index]?.priceRange?.name)
        )
    }, [isGift])
    return (
        <>
            {isCord && (
                <CordType
                    disabled={disabled}
                    isOpen={isCordOpen}
                    onClose={handleCloseCord}
                    control={controller}
                    setValue={setValue}
                    prefixName={`products[${index}]`}
                    state={state.products[index]}
                />
            )}
            {isGift && (
                <GiftOptions
                    disabled={disabled}
                    isOpen={isGiftOpen}
                    onClose={handleCloseGift}
                    control={controller}
                    setValue={setValue}
                    prefixName={`products[${index}]`}
                    state={state.products[index]}
                />
            )}
            <div
                ref={preview}
                className={`p-2 ${
                    error ? 'border border-red-500 rounded' : ''
                } grid grid-cols-1 w-full ${
                    !isOdd ? 'bg-neutral-100/95 dark:bg-neutral-600' : ''
                }`}
            >
                <div className="grid grid-cols-12">
                    <div className="flex col-span-12">
                        <div className="flex flex-row justify-center items-center pr-2">
                            {hasDragAndDrop && active && !disabled && (
                                <div ref={drag} className="cursor-move">
                                    <MdDragHandle />
                                </div>
                            )}
                            <Controller
                                render={({ field }) => (
                                    <Switch
                                        disabled={disabled}
                                        size="small"
                                        {...field}
                                        alt="Ativar produto"
                                        checked={field.value}
                                    />
                                )}
                                name={`products[${index}].isActive`}
                                control={controller}
                            />
                        </div>
                        <div className="w-full">
                            <AutoForm inputs={inputs} />
                        </div>
                        <div className="flex lg:flex-row md:flex-col col-span-1 justify-center items-center gap-1 pl-2">
                            {!disabled && (
                                <IconButton
                                    size="small"
                                    title="Duplicar"
                                    onClick={duplicateProposal}
                                >
                                    <IoDuplicate size={20} />
                                </IconButton>
                            )}
                            {!isUnique && !disabled && (
                                <IconButton
                                    title="Excluir"
                                    size="small"
                                    onClick={onDelete}
                                >
                                    <BsFillTrashFill />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    {markupLimits && noMarkupLimit && isAlertOpen && (
                        <div className="w-full col-span-12 py-2">
                            <Alert severity="warning" onClose={toggleAlert}>
                                <AlertTitle>Atenção!</AlertTitle>
                                Você está fora dos limites indicados de markup
                                para este produto{' '}
                                {state.products[index]?.minMarkup}% até{' '}
                                {state.products[index]?.maxMarkup}%
                                <br />
                                Você tem permissão para ignorar este limite.
                            </Alert>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
