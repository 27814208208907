import { db } from '@/utils/firebase'
import { User } from '@Core/Entities/User'
import { Button, IconButton } from '@material-ui/core'
import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import { version } from '../../../../../package.json'
import { useUserProfileMenu } from './Hooks/useUserProfileMenu'
import {
    ProfileMenuContainer,
    UpdateContainer,
    UserName,
    UserPicture,
    UserProfileContainer,
} from './UserProfile.styles'
import { Version } from './components/Version/Version'

export const UserProfile = ({ user }: { user: Partial<User> }) => {
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false)
    const [hasANewVersion, setHasANewVersion] = useState(false)
    const { menu } = useUserProfileMenu()

    const getUserFullName = () => {
        return `${user.givenName} ${user?.surname || ''}`
    }

    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen)
    }

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (
                isProfileMenuOpen &&
                !event?.target?.closest?.('[data-profile-menu]')
            ) {
                setIsProfileMenuOpen(false)
            }
        }
        document.addEventListener('click', handleOutsideClick)
        return () => document.removeEventListener('click', handleOutsideClick)
    })

    useEffect(() => {
        const unsub = onSnapshot(doc(db, 'version', 'current'), (doc) => {
            const minVersion = doc.data()?.v
            if (minVersion && minVersion > version) {
                setHasANewVersion(true)
            }
        })
        return unsub
    })

    useEffect(() => {
        if (hasANewVersion) {
            setShowUpdate(true)
        }
    }, [hasANewVersion])

    return (
        <div>
            <UserProfileContainer data-profile-menu onClick={toggleProfileMenu}>
                {!user.photo ? (
                    <>
                        {Boolean(user.givenName && getUserFullName()) ? (
                            <UserPicture
                                src={`https://ui-avatars.com/api/?name=${getUserFullName()}&background=706f6f&color=fff`}
                                alt={getUserFullName()}
                            />
                        ) : (
                            <Skeleton circle width={40} height={40} />
                        )}
                    </>
                ) : (
                    <>
                        <UserPicture src={user.photo} alt={getUserFullName()} />
                    </>
                )}
                <UserName>
                    {!user.givenName ? (
                        <Skeleton width={100} height={20} />
                    ) : (
                        getUserFullName()
                    )}
                </UserName>
            </UserProfileContainer>
            {showUpdate && (
                <UpdateContainer>
                    <div>
                        Atualização disponível{' '}
                        <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => window.location.reload()}
                        >
                            recarregue
                        </Button>{' '}
                        a pagina
                    </div>
                    <IconButton
                        color="secondary"
                        onClick={() => setShowUpdate(false)}
                    >
                        <AiOutlineClose />
                    </IconButton>
                </UpdateContainer>
            )}
            {isProfileMenuOpen && (
                <ProfileMenuContainer>
                    {menu?.map(({ icon: Icon, label, onClick }) => (
                        <p onClick={onClick} key={label}>
                            <Icon /> {label}
                        </p>
                    ))}
                    <Version>Versão: {version}</Version>
                </ProfileMenuContainer>
            )}
        </div>
    )
}
