import { initializeApp } from 'firebase/app'
import { getDatabase, ref } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: 'bshub-16220.firebaseapp.com',
    projectId: 'bshub-16220',
    databaseURL: 'https://bshub-16220-default-rtdb.firebaseio.com',
    storageBucket: 'bshub-16220.appspot.com',
    messagingSenderId: '571147575648',
    appId: '1:571147575648:web:115356d74e204f85f73e05',
    measurementId: 'G-1V5PBHRFEX',
}

export const app = initializeApp(firebaseConfig)
export const perf = getPerformance(app)
export const database = getDatabase(app)
export const db = getFirestore(app)
export const presenceRef = ref(database, 'presence')
export const storage = getStorage(app);
