import { InventoryMenu } from '@/packages/InventoryControl'
import { BalanceMenu } from '@Balance/index'
import { CommercialMenu } from '@Commercial/index'
import { RootState } from '@Core/Communication'
import { setSidebarCollapsedAction } from '@Core/Communication/GlobalStore/Reducers/appconfig/reducer'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { roles } from '@Entry/roles'
import { UserMenu } from '@User/index'
import { uniqueId } from 'lodash'
import { useCallback } from 'react'
import {
    AiOutlineHome,
    AiOutlineLock,
    AiOutlineVerticalLeft,
} from 'react-icons/ai'
import { BiCog } from 'react-icons/bi'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RoutesPaths } from '../RoutesPaths'
import { SidebarContainer, SidebarContent, SidebarItem } from './Sidebar.styles'
import { Indicator } from './components/Indicator/Indicator'

export const Sidebar = () => {
    const { user } = useSelector((state: RootState) => state.user)
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { sidebarCollapsed } = useSelector(
        (state: RootState) => state.appConfig
    )

    const toggleMenu = useCallback(() => {
        dispatch(setSidebarCollapsedAction(!sidebarCollapsed))
    }, [sidebarCollapsed])

    return (
        <SidebarContainer collapsed={sidebarCollapsed}>
            <Indicator />
            <SidebarContent>
                {!user &&
                    new Array(5).fill('').map(() => (
                        <SidebarItem className="sidebar-item" key={uniqueId()}>
                            <Skeleton width={sidebarCollapsed ? 30 : 230} />
                        </SidebarItem>
                    ))}
                {user && (
                    <SidebarItem
                        to={RoutesPaths.Home}
                        active={pathname === RoutesPaths.Home}
                        className="sidebar-item"
                        activeClassName="active"
                    >
                        <>
                            <AiOutlineHome size={18} />
                            <span className="sidebar-text">Home</span>
                        </>
                    </SidebarItem>
                )}
                <BalanceMenu />
                <InventoryMenu />
                <CommercialMenu />

                {user && (
                    <SidebarItem
                        active={false}
                        target="_blank"
                        rel="noopener noreferrer"
                        to={'https://portal.bragasolucoes.com.br/v/vault'}
                        className="sidebar-item"
                        activeClassName="active"
                    >
                        <AiOutlineLock size={18} />
                        <span className="sidebar-text">Cofre de senhas</span>
                    </SidebarItem>
                )}
                <UserMenu />
                {user && (
                    <PermissionWrapper
                        permission={roles.settings.permissions.read.key}
                    >
                        <SidebarItem
                            active={pathname === RoutesPaths.Settings}
                            to={RoutesPaths.Settings}
                            className="sidebar-item"
                            activeClassName="active"
                        >
                            <BiCog size={18} />
                            <span className="sidebar-text">Configurações</span>
                        </SidebarItem>
                    </PermissionWrapper>
                )}
                <SidebarItem
                    active={false}
                    onClick={toggleMenu}
                    className="sidebar-item"
                    activeClassName="active"
                >
                    <AiOutlineVerticalLeft
                        size={18}
                        className={sidebarCollapsed ? '' : 'rotate-180'}
                    />
                    <span className="sidebar-text">Minimizar</span>
                </SidebarItem>
            </SidebarContent>
        </SidebarContainer>
    )
}
