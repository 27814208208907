import { presenceRef } from '@/utils/firebase'
import { Container, ContainerHeader, Table } from '@Core/Components'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { useUsers } from '@User/Hooks/useUsers/useUsers'
import { RoutesPaths } from '@User/RoutesPaths'
import { roles } from '@User/roles'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { format } from 'date-fns'
import { limitToLast, onValue, orderByValue, query } from 'firebase/database'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnlineTag } from './UserList.styles'

export const UserList = () => {
    const { users, loading } = useUsers()
    const navigate = useNavigate()
    const [onlineUsers, setOnlineUsers] = useState([])

    const handleNavigate = (uid: string) => () => {
        navigate(formatRouteId(RoutesPaths.UserDetail, uid))
    }

    const isOnline = useCallback(
        (id: string) => {
            if (!id) return false
            // @ts-ignore
            return onlineUsers?.[id] === true
        },
        [onlineUsers]
    )

    useEffect(() => {
        const onlineUsersQuery = query(
            presenceRef,
            orderByValue(),
            limitToLast(10)
        )
        onValue(onlineUsersQuery, (snapshot) => {
            const onlineUsers = snapshot.val()
            setOnlineUsers(onlineUsers)
        })
    }, [])

    if (loading) return <Loader />

    return (
        <PermissionWrapper permission={roles.user.permissions.read.key}>
            <Container>
                <ContainerHeader>
                    <h1 className="text-2xl font-bold">Usuários</h1>
                </ContainerHeader>
                <Table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Ultimo Login</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((user) => (
                            <tr
                                className="clickable"
                                key={user._id}
                                onClick={handleNavigate(user.uid)}
                            >
                                <td>
                                    <OnlineTag
                                        isOnline={isOnline(user._id)}
                                        variant={
                                            isOnline(user._id)
                                                ? 'default'
                                                : 'outlined'
                                        }
                                        size="small"
                                        label={
                                            isOnline(user._id)
                                                ? 'Online'
                                                : 'Offline'
                                        }
                                    />
                                </td>
                                <td>{user.name || user?.displayName}</td>
                                <td>{user.mail}</td>
                                <td>
                                    {format(
                                        // @ts-ignore
                                        new Date(
                                            user?.metadata?.lastSignInTime
                                        ),
                                        'dd/MM/yyyy HH:mm'
                                    ) ?? 'N/D'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>
        </PermissionWrapper>
    )
}
