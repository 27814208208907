import { createHoverColor } from '@Utils/Formatters/hoverColor'
import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import { getContrastColor } from './../../../Utils/Formatters/constrastColor'
import { ThemeType } from './Light'
import { auxiliaries } from './theme.utils'

export const darkTheme: ThemeType = {
    isDarkMode: true,
    body: '#111',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#111',
    sidebarColor: '#333',
    headerColor: '#333',
    borderColor: '#FAFAFA',
    colors: {
        primary: '#f07d00',
        secondary: '#706f6f',
        tertiary: 'transparent',
        input: {
            background: '#333',
            focusBorder: '#f07d00',
            border: '#FAFAFA',
            label: '#FAFAFA',
            labelFocus: '#f07d00',
            placeholder: '#FAFAFA90',
            supportMessage: {
                error: '#ef4781',
                warning: '#f5a623',
                info: '#4ac4d6',
                success: '#28a745',
            },
        },
        button: {
            primaryHover: createHoverColor('#f07d00'),
            primaryText: getContrastColor('#f07d00'),
            secondaryHover: createHoverColor('#706f6f'),
            secondaryText: '#fff',
            tertiaryHover: createHoverColor('transparent'),
            tertiaryText: '#FAFAFA',
        },
        table: {
            inactiveRow: convertHexToRGBA('#706f6f', 0.1),
            oddRow: 'rgba(255, 255, 255, 0.05)',
            hoverRow: 'rgba(255, 255, 255, 0.1)',
            warningRow: '#f5a623',
            dangerRow: '#ef4781',
            successRow: '#28a745',
        },
        container: {
            background: '#333',
        },
    },
    ...auxiliaries,
}
