import { getContrastColor } from '@Utils/Formatters/constrastColor'
import { createHoverColor } from '@Utils/Formatters/hoverColor'
import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import { auxiliaries } from './theme.utils'

export const lightTheme = {
    isDarkMode: false,
    body: 'rgba(0,0,0,0.07)',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#fff',
    sidebarColor: '#fff',
    headerColor: '#fff',
    borderColor: '#363537',
    colors: {
        primary: '#f07d00',
        secondary: '#706f6f',
        tertiary: 'transparent',
        input: {
            background: '#fff',
            focusBorder: '#f07d00',
            border: '#363537',
            label: createHoverColor(getContrastColor('#fff')),
            labelFocus: '#f07d00',
            placeholder: '#363537',
            supportMessage: {
                error: '#ef4781',
                warning: '#f5a623',
                info: '#4ac4d6',
                success: '#28a745',
            },
        },
        button: {
            primaryHover: createHoverColor('#f07d00'),
            primaryText: getContrastColor('#f07d00'),
            secondaryHover: createHoverColor('#5d5d5d'),
            secondaryText: '#fff',
            tertiaryHover: createHoverColor('transparent'),
            tertiaryText: '#363537',
        },
        table: {
            inactiveRow: convertHexToRGBA('#5d5d5d', 0.1),
            oddRow: 'rgba(0, 0, 0, 0.05)',
            hoverRow: 'rgba(0, 0, 0, 0.1)',
            warningRow: '#f5a623',
            dangerRow: '#ef4781',
            successRow: '#28a745',
        },
        container: {
            background: '#fff',
        },
    },
    ...auxiliaries,
}

export type ThemeType = typeof lightTheme
