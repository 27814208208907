import { UsersRepository } from '@User/Data/Repository/UsersRepository'
import {
    GetUsersUseCase,
    GetUserUseCase,
    UpdateUserUseCase,
} from '@User/Domain/UseCases'
import { Container } from 'inversify'
import { UserAPI } from '../API/UserAPI'
import { Register } from './UserRegister'

const container = new Container()
container
    .bind<GetUserUseCase>(Register.UseCaseGetUser)
    .toDynamicValue(
        () => new GetUserUseCase(new UsersRepository(new UserAPI()))
    )
container
    .bind<GetUsersUseCase>(Register.UseCaseGetUsers)
    .toDynamicValue(
        () => new GetUsersUseCase(new UsersRepository(new UserAPI()))
    )
container
    .bind<UpdateUserUseCase>(Register.UseCaseUpdateUser)
    .toDynamicValue(
        () => new UpdateUserUseCase(new UsersRepository(new UserAPI()))
    )

export default container
