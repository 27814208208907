import {
    GetUsersUseCase,
    GetUserUseCase,
    UpdateUserUseCase,
} from '@User/Domain/UseCases'
export const Register = {
    UseCaseGetUsers: GetUsersUseCase,
    UseCaseGetUser: GetUserUseCase,
    UseCaseUpdateUser: UpdateUserUseCase,
}
